@import url("https://fonts.googleapis.com/css2?family=Jacquard+12&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

/* Global Box Sizing */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Reset body margin */
body {
  margin: 0;
  height: 100%;
  overflow: hidden; /* Prevents scrolling */
}

.App {
  font-family: "Poppins", sans-serif;
  background-color: #d9d9d9;
  color: #d9d9d9;
}

.container {
  margin: 0%;
  padding: 0%;
  display: flex;
  width: 100%;
  height: 100vh;
}

.sidebar {
  padding-left: 2.92rem;
  padding-top: 2.92rem;
  width: 20rem;
  margin: 0%;
}

.site-title-p1 {
  margin: 0%;
  color: #ababab;
  font-family: "Jacquard 12";
  font-size: 4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 3.84615rem; /* 78.125% */
}

.site-title-p2 {
  margin-top: 3rem;
  color: #919191;
  font-family: Poppins;
  font-size: 2rem;
  font-style: normal;
  font-weight: 275;
  line-height: 0.92308rem; /* 33.333% */
}

.sidebar ul {
  list-style-type: none;
  padding-top: 5rem;
  padding-left: 0;
  margin: 0;
}

.sidebar li {
  padding-top: 3rem;
  cursor: pointer;
  color: #000;
  font-family: Poppins;
  font-size: 2rem;
  font-style: normal;
  font-weight: 275;
  line-height: 0.92308rem;
}

.sidebar a {
  text-decoration: none;
  color: inherit;
  display: block;
}

.sidebar li:hover {
  color: #919191;
}

.main {
  padding: 0%;
  margin: 0%;
  flex: 1;
  min-width: 0;
}

.slider-container {
  padding: 0 100px;
  color: #000;
}

/* Style for each slide */
.image-slide {
  /* position: relative; */
}

/* Make images responsive and fit within the container */
.image-slide img {
  width: 100%;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.active-link {
  font-weight: 300; /* Optional */
}
